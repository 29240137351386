import React from 'react';

import { getLocalizedTheme, Theme as IBOXTheme } from '@ibox/ui';

import { setFavicon } from '../../helpers';
import { useAppSelector } from '../../store';

import './index.scss';

type ThemeProps = {
  children: string | JSX.Element | JSX.Element[];
};

export const Theme = ({ children }: ThemeProps) => {
  const language = useAppSelector((state) => state.settings.language);
  const countryCode = useAppSelector(
    (state) => state.settings.common.countryCode
  );

  React.useEffect(() => {
    setFavicon(countryCode);
    const body = document.getElementById('body');
    if (body) {
      body.className = `theme-${countryCode.toLowerCase()}`;
    }
  }, [countryCode]);

  const theme = React.useMemo(
    () => getLocalizedTheme(countryCode, language),
    [countryCode, language]
  );

  return <IBOXTheme theme={theme}>{children}</IBOXTheme>;
};
