import { useCallback } from 'react';

import { FetchArgs } from '@reduxjs/toolkit/dist/query';

import { saveFileFromResponse } from '@ibox/ui';

import { useAppDispatch } from '../store';

import {
  apiGenerated,
  type GetPrefixesApiArg,
  type GetPrefixesApiResponse,
  type GetProductApiResponse,
  type GetProductGroupApiResponse,
  type GetProductGroupsApiArg,
  type GetProductGroupsApiResponse,
  type GetTasksApiArg,
  type GetTasksApiResponse,
  type OrganizationFull,
  type TaskResponse,
  type GetOrganizationsApiArg,
  type GetOrganizationsApiResponse,
  type GetProductsApiResponse,
  type GetProductsApiArg,
  type GetEnumApiResponse,
  type GetEnumApiArg,
  type ProductCategoryResponse,
  type TnvedClassifierWithCategories,
  type ProductCategoryFullResponse,
} from './api-generated';

type PaginatedResponse<T> = {
  content: T;
  isLastPage: boolean;
};

type GetPaginatedTasksApiResponse = GetTasksApiResponse & {
  isLastPage: boolean;
};

const createPaginatedEntity = <
  GetEntityApiResponse extends unknown[],
  GetEntityApiArg extends { limit?: number }
>(
  url: string
) => {
  type GetEntityPaginatedApiResponse = PaginatedResponse<GetEntityApiResponse>;
  return {
    query: (queryArg: GetEntityApiArg) => ({
      url,
      params: {
        ...queryArg,
        limit: (queryArg.limit ?? 0) + 1,
      },
      credentials: 'omit',
    }),
    serializeQueryArgs: ({ endpointName }: { endpointName: string }) =>
      endpointName,
    merge: (
      currentCache: GetEntityPaginatedApiResponse,
      { content, isLastPage }: GetEntityPaginatedApiResponse
    ) => {
      currentCache.content?.push(...content);
      currentCache.isLastPage = isLastPage;
    },
    transformResponse: (
      response: GetEntityApiResponse,
      _meta: unknown,
      arg: GetEntityApiArg
    ): GetEntityPaginatedApiResponse => {
      const isLastPage = (arg.limit ?? 0) + 1 > response.length;
      return {
        isLastPage,
        content: (isLastPage
          ? response
          : response.slice(0, -1)) as GetEntityApiResponse,
      };
    },
  };
};

const transformEnumResponse = ({ values }: GetEnumApiResponse) => values || [];

export const api = apiGenerated
  .injectEndpoints({
    overrideExisting: true,
    endpoints: (build) => ({
      getProductGroupEnum: build.query<
        GetProductGroupsApiResponse,
        Pick<GetProductGroupsApiArg, 'sortLanguage'>
      >({
        query: (queryArg) => ({
          url: `/api/v1/pg`,
          params: { sortLanguage: queryArg.sortLanguage },
        }),
      }),

      getEnum: build.query<GetEnumApiResponse['values'], GetEnumApiArg>({
        query: (queryArg) => ({
          url: `/api/v1/enum/${queryArg.name}`,
          params: { sortLanguage: queryArg.sortLanguage },
        }),
        transformResponse: transformEnumResponse,
      }),

      getPackageTypeEnum: build.query<
        GetEnumApiResponse['values'],
        Pick<GetEnumApiArg, 'sortLanguage'>
      >({
        query: (queryArg) => ({
          url: `/api/v1/enum/package_type`,
          params: { sortLanguage: queryArg.sortLanguage },
        }),
        transformResponse: (response: GetEnumApiResponse) =>
          transformEnumResponse(response).map((value) => ({
            ...value,
            // TODO: баг бекенда - в параметрах фильтрации бекенд ждет package_type в upperCase
            // надо выровнять на стороне бекенда
            code: value.code.toUpperCase(),
          })),
      }),

      getPaginatedOrganizations: build.query<
        PaginatedResponse<GetOrganizationsApiResponse>,
        GetOrganizationsApiArg
      >(
        createPaginatedEntity<
          GetOrganizationsApiResponse,
          GetOrganizationsApiArg
        >('/api/v1/admin/organizations')
      ),

      getPaginatedPrefixes: build.query<
        PaginatedResponse<GetPrefixesApiResponse>,
        GetPrefixesApiArg
      >(
        createPaginatedEntity<GetPrefixesApiResponse, GetPrefixesApiArg>(
          '/api/v1/prefixes'
        )
      ),

      getPaginatedProducts: build.query<
        PaginatedResponse<GetProductsApiResponse>,
        GetProductsApiArg
      >(
        createPaginatedEntity<GetProductsApiResponse, GetProductsApiArg>(
          '/api/v1/product'
        )
      ),

      getPaginatedProductGroups: build.query<
        PaginatedResponse<GetProductGroupsApiResponse>,
        GetProductGroupsApiArg
      >(
        createPaginatedEntity<
          GetProductGroupsApiResponse,
          GetProductGroupsApiArg
        >('/api/v1/pg')
      ),

      getPaginatedTasks: build.query<
        GetPaginatedTasksApiResponse,
        GetTasksApiArg
      >({
        query: (queryArg) => ({
          url: '/api/v1/task',
          params: {
            ...queryArg,
            limit: (queryArg.limit ?? 0) + 1,
          },
        }),
        serializeQueryArgs: ({ endpointName }) => endpointName,
        merge: (currentCache, { total, content, isLastPage }) => {
          currentCache.total = total;
          currentCache.content?.push(...content);
          currentCache.isLastPage = isLastPage;
        },
        transformResponse: (
          response: GetTasksApiResponse,
          _meta,
          arg
        ): GetPaginatedTasksApiResponse => {
          const isLastPage = (arg.limit ?? 0) + 1 > response.content.length;
          return {
            ...response,
            isLastPage,
            content: isLastPage
              ? response.content
              : response.content.slice(0, -1),
          };
        },
      }),
    }),
  })
  .enhanceEndpoints({
    endpoints: {
      getTaskReport(endpoint) {
        const query = endpoint.query;
        if (query) {
          endpoint.query = (queryArg) => ({
            ...(query(queryArg) as FetchArgs),
            // Save xls report to file
            responseHandler: (response) =>
              saveFileFromResponse(response).then((response) => response.ok),
          });
        }
      },
    },
  });

export const useEmptyEndpointCache = (
  endpointName:
    | 'getPaginatedProductGroups'
    | 'getPaginatedProducts'
    | 'getPaginatedOrganizations'
    | 'getPaginatedPrefixes'
    | 'getPaginatedTasks'
) => {
  const dispatch = useAppDispatch();
  const emptyEndpointCache = useCallback(() => {
    dispatch(
      api.util.updateQueryData(endpointName, {}, () => ({
        content: [],
        isLastPage: false,
        total: 0,
      }))
    );
  }, [dispatch, endpointName]);
  return emptyEndpointCache;
};

export type TaskData = TaskResponse;
export type TaskStatus = TaskData['status'];

export type PrefixData = GetPrefixesApiResponse[number] &
  Required<Pick<GetPrefixesApiResponse[number], 'code' | 'inn'>>;
export type PrefixStatus = Required<PrefixData>['status'];

export type ParticipantData = OrganizationFull;
export type ParticipantStatus = Required<ParticipantData>['status'];
export type ParticipantStatusExt = Required<ParticipantData>['status_ext'];
export type ParticipantSource = Required<ParticipantData>['source'];

export type Product = GetProductApiResponse;
export type ProductStatus = Required<Product>['status'];

export type ProductCategory = ProductCategoryResponse;
export type ProductCategoryFull = ProductCategoryFullResponse;

export type ProductGroupData = GetProductGroupApiResponse;
export type ProductGroupStatusCode = Required<
  Required<ProductGroupData>['status']
>['code'];
export type ProductGroupModeration = Required<ProductGroupData>['moderation'];
export type PackageType = Required<ProductGroupData>['packages'][number];

export type Tnved = TnvedClassifierWithCategories;

export type EnumValue<T = string> = {
  code: T;
  translations: {
    [key: string]: string;
  };
};
export type Enum<T = string> = EnumValue<T>[];

export * from './api-generated';
